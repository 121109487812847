#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}
.container,
.auth,
.flex-1 {
  flex-grow: 1;
  position: relative;
}
.main {
  padding-bottom: 100px;
}
.header_inner {
  min-height: 54px;
}
.table {
  margin-top: 30px;
  overflow-x: auto;
}
.table_title {
  font-family: "MuseoCyrl-700", sans-serif;
}
.table table {
  min-width: 860px;
  margin-top: 25px;
  width: 100%;
  border-collapse: collapse;
}
.table table td,
.table table th {
  padding: 13px 23px;
  text-align: center;
}
.table table th {
  border-left: 1px solid #000;
}
.table table td {
  border-left: 1px solid #d4d1d1;
}
.table table a {
  text-decoration: none;
  color: #879de9;
}
.table thead tr:last-child th {
  border-left: none;
  border-right: none;
  padding: 5px;
}
.table tr th:last-child {
  border-right: 1px solid #000;
}
.table tr td:last-child {
  border-right: 1px solid #d4d1d1;
}
.table_create_title {
  display: flex;
  align-items: center;
}
.table_create {
  margin-left: 15px;
  cursor: context-menu;
}
.table_body_separated tr:not(:last-child) {
  border-bottom: 2px dashed #000;
}
.admin_icons {
  width: 20px;
}
.admin_icons:first-child,
.admin_icons:nth-child(2) {
  margin-right: 15px;
}
.admin_icons img {
  width: 22px;
  height: 20px;
}
.button_icon {
  width: 22px;
  height: 20px;
}
.button_add {
  width: 30px;
  height: 30px;
  background-color: #879de9;
  border: 1px solid #879de9;
  cursor: pointer;
  color: #fff;
  border-radius: 2px;
  outline: none;
  font-size: 18px;
}
.button_add:hover {
  background-color: #fff;
  color: #879de9;
}

/* Modals */
.modal {
  position: fixed;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 500px;
  min-height: 200px;
  max-height: 500px;
  overflow-y: auto;
  padding: 15px;
  background-color: #242b42;
  color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}
.modal_title {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.cancel {
  width: 20px;
}
.modal_form {
  display: flex;
  flex-direction: column;
}
.modal_form label {
  margin-bottom: 10px;
}
.modal_form label:not(:first-child) {
  margin-top: 10px;
}
.modal_form input,
option {
  height: 30px;
}
.modal_form button {
  margin-top: 10px;
  padding: 5px;
}
.modal_delete_button {
  margin-top: 30px;
  padding: 5px;
}
.multi-select {
  color: #242b42;
}

.footer {
  margin-top: 0;
}

.lds-roller {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background-color: transparent;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #242b42;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.basic-multi-select {
  color: #242b42;
  margin-top: 10px;
}
.form_roles_label {
  display: flex;
  align-items: center;
}
.form_roles_label input {
  margin-right: 10px;
}
.crud {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.crud_title {
}
.crud_form,
.crud_form_block {
  display: flex;
  flex-direction: column;
  max-width: 700px;
}
.crud_form_block {
  margin-top: 15px;
  border: 1px dashed #242b42;
  border-radius: 5px;
  padding: 10px;
}
.crud_form label,
.crud_form_block label {
  margin-top: 20px;
  margin-bottom: 10px;
}
.crud_form .crud_checkbox,
.crud_form_block .crud_checkbox {
  margin-top: 0;
  margin-bottom: 0;
}
.crud_form input,
.crud_form_block input {
  height: 30px;
}
.crud_form button,
.crud_form_block button {
  margin-top: 20px;
  padding: 5px 10px;
}
.crud_back {
  margin-top: 20px;
}
.crud_checkbox {
  display: flex;
  align-items: center;
}
.crud_checkbox input {
  margin-right: 10px;
}
.react-datetimerange-picker__wrapper {
  flex-grow: initial !important;
}
.react-datetimerange-picker__wrapper button {
  margin-top: 0;
}
.student_practice_table_body tr td:last-child {
}
.student_practice_table_body tr td:nth-child(3) {
  min-width: 100px;
}
.comment_textarea {
  width: 100%;
  min-height: 100px;
  resize: vertical;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column textarea,
.flex-column select,
.flex-column button {
  align-self: baseline;
}
.flex-column > textarea {
  min-width: 400px;
}
.flex-column label {
  margin: 15px 0;
}
.flex-column > button {
  margin-top: 15px;
}
.flex-column > input {
  max-width: 150px;
}

.alert {
  position: fixed;
  top: 60px;
  right: 15px;
  color: #fff;
  padding: 10px 15px;
  font-family: "MuseoCyrl-700", sans-serif;
  z-index: 2;
}
.alert-fail {
  background-color: red;
}
.alert-success {
  background-color: #897de9;
}
.d-flex {
  display: flex;
}
.d-flex label:not(:first-child) {
  margin-left: 15px;
}
.d-flex label input[type="checkbox"] {
  margin-right: 5px;
}
.align-items-center {
  align-items: center;
}
.mt-10 {
  margin-top: 10px;
}
.filter_block {
  display: flex;
  flex-direction: column;
}
.filter_block_search {
  display: flex;
}
.filter_block_search input {
  margin-right: 15px;
}
.filter_block_group {
  display: flex;
  flex-direction: column;
}
.filter_block_group label {
  margin-bottom: 10px;
}
.filter_block_bottom {
  display: flex;
  align-items: center;
  align-self: baseline;
}
.filter_block_role {
  align-self: baseline;
}
.filter_button {
  margin-left: 15px;
}
.reset_filter_button {
  cursor: pointer;
  background-color: rgb(240, 18, 18);
  color: #fff;
  padding: 5px 10px;
  outline: none;
  border-radius: 2px;
  border: 1px solid rgb(240, 18, 18);
}
.reset_filter_button:hover {
  background-color: transparent;
  color: rgb(240, 18, 18);
}
.form {
  transform: translateY(50px);
  display: flex;
  flex-direction: column;
  align-self: baseline;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 45px 15px;
  margin: 0 auto;
  max-width: 500px;
  border-radius: 5px;
  background-color: #242b42;
}
.form > *:not(:last-child) {
  margin-bottom: 20px;
}
.form * {
  font-size: 18px;
}
.form_header {
  font-size: 20px;
}
.logo {
  display: flex;
  justify-content: center;
}
.form_block {
  display: flex;
  flex-direction: column;
  color: #fff;
}
.form label {
  margin-bottom: 10px;
  color: #fff;
}
.form input {
  padding: 2px;
  border: none;
  border-bottom: 2px solid #fff;
  background-color: #242b42;
  color: #fff;
  width: 100%;
}
.form input:focus {
  outline: none;
}
.form button {
  font-family: "MuseoCyrl-500";
  padding: 10px 15px;
  display: flex;
  align-self: center;
  border: 2px solid transparent;
  border-radius: 5px;
  background-color: #879de9;
  color: #fff;
}
.form button:hover {
  border: 2px solid #879de9;
  background-color: #fff;
  color: #897de9;
  cursor: pointer;
}
.form_link {
  display: flex;
  align-self: flex-start;
  color: #fff;
}
.form_link:hover {
  color: #879de9;
}
.t-center {
  text-align: center;
}
.form input:-webkit-autofill,
.form input:-webkit-autofill:hover,
.form input:-webkit-autofill:focus,
.form input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #242b42 inset !important;
  -webkit-text-fill-color: #fff !important;
}

.not_found {
  text-align: center;
}
.not_found_number {
  font-size: 200px;
}
.not_found_text {
  font-size: 26px;
}
.not_found_link {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 22px;
}
.multiselect-container {
  width: initial !important;
}
.comment {
  background-color: #879de9;
  color: #242b42;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comment:hover {
  color: #fff;
}
.comment img {
  width: 15px;
  height: 15px;
}
.comment img:first-child {
  margin-right: 10px;
}
.student_practice_table_body tr:nth-child(2n + 1) {
  border-bottom: 1px solid #d4d1d1;
}
.student_practice_table_body tr:first-child,
.student_practice_table_body tr:last-child {
  border-bottom: none;
}
.mt-15 {
  margin-top: 15px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-10 {
  margin-bottom: 10px;
}
.qr_signature {
  margin-top: 15px;
  font-weight: bold;
  font-size: 24px;
}
.input_error {
  color: rgb(235, 70, 70);
}
.not_auth_header_link {
  display: none;
  align-items: center;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
}
.button {
  background-color: #879de9;
  padding: 5px 10px;
  border: 1px solid #879de9;
  cursor: pointer;
  color: #fff;
  border-radius: 2px;
  outline: none;
}
.button:hover {
  background-color: #fff;
  color: #879de9;
}
.button_disabled {
  padding: 5px 10px;
  background-color: #ccc;
  color: #000;
  border: none;
  border-radius: 2px;
}
.link,
.crud_back {
  color: #879de9;
  text-decoration: none;
  cursor: pointer;
}
.link:hover,
.crud_back:hover {
  color: #242b42;
}
a {
  text-decoration: none;
}
.table_wrapper {
  overflow-x: auto;
}
.paginated {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.paginated_item {
  cursor: pointer;
  padding: 5px 10px;
  background-color: #879de9;
  color: #fff;
  border: 1px solid transparent;
}
.paginated_item:hover {
  background-color: #fff;
  color: #879de9;
  border: 1px solid #879de9;
  border-radius: 2px;
}
.paginated_item:not(:last-child) {
  margin-right: 15px;
}
.paginated_item_disabled {
  background-color: #ccc;
  color: #000;
  border: 1px solid transparent;
  cursor: initial;
}
.paginated_item_disabled:hover {
  background-color: #ccc;
  color: #000;
  border: 1px solid transparent;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.video {
  display: flex;
  max-width: 100%;
}
.logo_icon {
  width: 80px;
  height: auto;
}
.jcon-center {
  justify-content: center;
}
.b-top-dashed {
  border-top: 1px dashed #000;
}
.b-bottom-dashed {
  border-bottom: 1px dashed #000;
}
.accordion_item {
  display: flex;
  flex-direction: column;
}
.accordion_item_heading {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  color: #242b42;
  cursor: pointer;
}
.accordion_item_body {
  padding: 10px 40px 10px 40px;
}
.accordion_item_content p {
  padding: 10px;
}
.attention {
  max-width: 700px;
}
.button--red {
  background-color: red;
  border: 1px solid red;
}
.button--red:hover {
  color: red;
}
.modal_form textarea {
  outline: none;
  padding: 5px;
}
.statistics {
  padding: 10px;
  max-width: 568px;
}
.statistics h2 {
  margin-bottom: 20px;
}
.statistics strong {
  font-size: 20px;
}
.statistics li {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 2px solid #000;
  padding: 5px 10px;
}
.statistics li:last-child {
  border-bottom: 2px solid #000;
}
.statistics li:not(:last-child) {
  margin-bottom: 10px;
}
.statistics li p {
  margin-right: 20px;
}

@media screen and (max-width: 530px) {
  .form {
    width: 100%;
  }
}
